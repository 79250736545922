import React from "react";
import "./header.css";
import CTA from "./CTA";
import me from "../../assets/me.png";
import HeaderSocials from "./headerSocials";
const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <div className="test">
          <div className="me">
            <img src={me} alt="me" />
          </div>
          <div className="texts">
            <h5>Hello I'm</h5>
            <h1>Sebastián VaRom</h1>
            <h5 className="text-light">
              Fullstack developer, UX Designer, IT Support & Tester
            </h5>
          </div>
        </div>
        <CTA />
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
