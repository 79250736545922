import React from "react";
import "./portfolio.css";
import IMG from "../../assets/learnapp.jpg";

const data = [
  {
    image: IMG,
    title: "LearnApp",
    github: "https://github.com/SVaRom/LearnApp",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ image, title, github }, index) => {
          return (
            <article key={index} className="portfolio__items">
              <div className="portfolio__items-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__items-cta">
                <a href={github} className="btn" rel="external">
                  Github
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
