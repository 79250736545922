import React from "react";
import "./about.css";
import me from "../../assets/profile.jpg";
import { GiStairsGoal } from "react-icons/gi";
import { IoSchoolSharp } from "react-icons/io5";
import { BsSpotify } from "react-icons/bs";

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={me} alt="suit up" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <GiStairsGoal className="about__icon" />
              <h5>Experience</h5>
              <small>Just college projects</small>
            </article>
            <article className="about__card">
              <IoSchoolSharp className="about__icon" />
              <h5>College</h5>
              <small>Instituto Tecnológico de Aguascalientes</small>
            </article>
            <article className="about__card">
              <BsSpotify className="about__icon" />
              <h5>Favorite platform</h5>
              <small>"I'm Mr. Brightside"</small>
            </article>
          </div>
          <p>
            🔭 I'm Sebastián VaRom, but all my friends call me "Chobach". 🤔 ⚡
            <br /> 💬 I love all the tech stuff, and i wanna help u with ur
            college projects, if u need something please ask me. 😁
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
