import React from "react";
import { MdVerified } from "react-icons/md";
import "./services.css";
const Services = () => {
  return (
    <section id="services">
      <h5>What i offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <MdVerified className="service__list-icon" />
              <p>Elaboration of templates</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>User researching</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>User testing</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Fullstack</h3>
          </div>
          <ul className="service__list">
            <li>
              <MdVerified className="service__list-icon" />
              <p>Creation of webpages</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>Creation of mobile apps</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>Creation of desktop apps</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>IT Support</h3>
          </div>
          <ul className="service__list">
            <li>
              <MdVerified className="service__list-icon" />
              <p>Network installation</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>Computer equipment maintenance</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>Network configuration</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Tester</h3>
          </div>
          <ul className="service__list">
            <li>
              <MdVerified className="service__list-icon" />
              <p>Test case design</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>Selenium</p>
            </li>
            <li>
              <MdVerified className="service__list-icon" />
              <p>Unit tests</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
