import React from "react";
import "./experience.css";
import { MdVerified } from "react-icons/md";
const Experience = () => {
  return (
    <section id="experience">
      <h5>Skills</h5>
      <h2>My experience</h2>
      <div className="container experience__container">
        <div className="experience__tools">
          <h3>Tools</h3>
          <div className="experience__content">
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Laravel</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>PHP Unit</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>R</h4>
                <small className="text-light">Beginner</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>ReactJS</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>React Native</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Android studio</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>C++/C#</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Firebase</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>SQL Server</h4>
                <small className="text-light">Mid-level</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__achievements">
          <h3>Achievements</h3>
          <div className="experience__content">
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>CCNA</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>CCNA Security</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Basics of user experience design</h4>
                <small className="text-light">Certified</small>
              </div>
            </article>
            <article className="experience__details">
              <MdVerified className="experience__details-icon" />
              <div>
                <h4>Business Intelligence</h4>
                <small className="text-light">Diplomat</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
